<template>
    <div class="mx-auto my-3 d-flex flex-column history-order-bloc-wrapper">
        <div class="d-flex align-center">
            <img class="align-self-start" :src="picto" width="50" height="50"  />
            <div class="mx-4 d-flex flex-column order-info">
                <p class="my-0 font-italic primary--text font-weight-bold text-body-1">{{order.creationDate}}</p>
                <p class="my-0 text-subtitle-2"> {{$tc("orderNumberOfProducts", nbrProducts, {nbrProducts})}} - <span v-if="substate">{{$t(substate)}}</span></p>
                <div class="d-flex align-center" @click="handleMoreInfo">
                    <p class="mb-0 text-caption font-italic"> {{$t("historyOrderMoreInfo")}} <v-icon small>{{panel == 0 ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon></p>
                </div>
            </div>
            <div class="total">
                <p class="mb-0 text-body-1 font-weight-bold">{{formatPrice(order.totalAmount)}}</p>
            </div>
        </div>
        <div class="products-recap">
            <v-expansion-panels v-model="panel" flat>
                <v-expansion-panel class="transparent">
                    <v-expansion-panel-content class="history-order-expansion-panel">
                        <div>
                            <HistoryProductBloc v-for="(basketProduct, key) in order.basketProducts" class="my-2" :key="`history-product-bloc${key}`" :basketProduct="basketProduct" />
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script>
    import HistoryProductBloc from "@/components/HistoryProductBloc"
    export default {
        name: "HistoryOrderBloc",
        components: {
            HistoryProductBloc
        },
        props: {
            order: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                panel: null
            }
        },
        methods: {
            handleMoreInfo(){
                if(this.panel == 0){
                    this.panel = -1
                }else{
                    this.panel = 0
                }
            }
        },
        computed:{
            nbrProducts(){
                return this.order.basketProducts.reduce((acc, basketProduct) => basketProduct.qty + acc, 0)
            },
            picto(){
                let picto = null
                switch(this.order.orderOrigin){
                    case 'IEC_SCAN_AND_PAY':
                        picto = require("@/assets/img/picto_scan_pay.png")
                        break;
                    case 'IEC_CLICK_AND_COLLECT':
                        picto = require("@/assets/img/picto_click_collect.png")
                        break;
                    case 'IEC_PAY_AT_TABLE':
                        picto = require("@/assets/img/picto_pay_at_table.png")
                        break;
                    case 'IEC_PAY_AND_DRIVE':
                        picto = require("@/assets/img/picto_pay_and_drive.png")
                        break;
                }
                return picto
            },
            substate(){
                let substateTranslation
                switch(this.order.subState){
                    case 'NEW':
                        substateTranslation = "newSubstate"
                        break;
                    case 'IN_PREPARATION':
                        substateTranslation = "inPreparationSubstate"
                        break;
                    case 'PREPARED':
                        substateTranslation = "preparedSubstate"
                        break;
                    case 'FINISH':
                        substateTranslation = "finishSubstate"
                        break;
                    default:
                        substateTranslation = "newSubstate"
                        break;
                }
                return substateTranslation
            }
        },
    }
</script>

<style scoped>
    .products-recap{
        width: 100%;
    }
    .order-info{
        width: 100%;
    }
    .history-order-expansion-panel>>> .v-expansion-panel-content__wrap{
        padding: 0 !important;
        background: transparent;
    }

    .history-order-bloc-wrapper:before,
    .history-order-bloc-wrapper:after {
        content: '';
        position: absolute;
        left: 0px;
        height: 6px;
        width: 100%;
        background: radial-gradient(circle, transparent, transparent 50%, #FBFBFB 50%, #FBFBFB 100% ) -7px -2px / 16px 16px repeat-x;
    }

    .history-order-bloc-wrapper{
        position: relative;
        box-sizing: border-box;
        width: 90%;
        padding: 20px;
        border-radius: 3px;
        background: #FBFBFB;
        box-shadow: 3px 4px 5px #8080802e;
    }
    .history-order-bloc-wrapper:before {
        top: -5px;
        transform: rotate(180deg);
    }

    .history-order-bloc-wrapper:after {
        bottom: -5px;
    }
</style>