<template>
    <div class="mt-3 full-height history-wrapper">
        <div class="pb-10">
            <template v-if="filteredOrders.length > 0">
                <HistoryOrderBloc v-for="(order, key) in filteredOrders" :key="`order-${key}`" :order="order" />
            </template>
            <p v-else class="accent--text text-center font-italic text-h6">{{$t("historyNoOrderFound")}}</p>
        </div>
        <div class="py-3 d-flex align-center justify-center full-width history-filter">
            <div class="translucid-bg"></div>
            <v-btn color="primary" @click="showFilters = true" rounded> {{$t("historyFilter")}} </v-btn>
        </div>
        <v-bottom-sheet v-model="showFilters" inset>
            <v-sheet class="rounded-t-lg" min-height="350">
                <div class="d-flex flex-column px-4 py-3">
                    <span class="text-body-1 option-title">{{$t("historyFilterSubtitle")}}</span>
                    <span class="primary--text font-weight-bold text-h6 option-title">{{$t("historyFilterOrigin")}}</span>
                    <div class="filter-options">
                        <v-checkbox v-for="(filter, key) in filters.orderOrigin" class="pa-0 my-2" :key="`history-filter-${key}`" v-model="selectedFilters" :label="filter.label" color="primary" :value="filter" hide-details ></v-checkbox>
                    </div>
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
    import HistoryOrderBloc from "@/components/HistoryOrderBloc"
    import { mapState } from "vuex"
    const BasketService = require("@/services/BasketService")
    import {pageInfoMixin} from "@/mixins/pageInfoMixin"
    export default {
        components: {
            HistoryOrderBloc
        },
        mixins: [pageInfoMixin],
        data() {
            return {
                orders: [],
                showFilters: false,
                selectedFilters: [],
                filters: {
                    orderOrigin:[
                        {
                            label: "Click&Collect",
                            value: "IEC_CLICK_AND_COLLECT",
                        },
                        {
                            label: "Scan&Pay",
                            value: "IEC_SCAN_AND_PAY",
                        },
                        {
                            label: "Pay@Table",
                            value: "IEC_PAY_AT_TABLE",
                        },
                        {
                            label: "Pay&Drive",
                            value: "IEC_PAY_AND_DRIVE",
                        }
                    ]
                }
            }
        },
        async created(){
            try {
                let {data} = await BasketService.getAllFinishedOrders(this.customerToken);
                console.log(data)
                this.orders = data
            } catch (e) {
                console.error(e)
            }
        },
        computed:{
            ...mapState('customer', {
                customerToken: "token"
            }),
            filteredOrders(){
                if(this.selectedFilters.length > 0){
                    return this.orders.filter(order => this.selectedFilters.includes(order.orderOrigin))
                }
                return this.orders
            }
        }
    }
</script>

<style scoped>
    .history-wrapper{
        position: relative;
    }
    
    .history-filter{
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
    
    .filter-options{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
</style>