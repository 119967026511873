<template>
    <div class="d-flex history-product-bloc-wrapper">
        <img :src="productImgUrl" class="product-img" />

        <div class="product-infos px-2">
            <span class="font-weight-bold">{{translateName(product.name)}}</span>
            <span class="font-weight-bold primary--text product-total-price">{{formatPrice(product.price * basketProduct.qty)}}</span>
            <span class="primary--text product-price">{{formatPrice(product.price)}}/{{$t("unit")}}</span>
        </div>

        <v-spacer></v-spacer>

        <div class="product-qty">
            <span class="font-weight-bold primary--text">x{{basketProduct.qty}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HistoryProductBloc",
        props:{
            basketProduct: Object
        },
        computed:{
            product(){
                return this.basketProduct.product
            },
            productImgUrl(){
                return this.config.WS_URL + "/catalog/product/image/" + this.product.image
            }
        },
    }
</script>

<style scoped>

    .opacity{
        opacity: 0.5;
    }

    .history-product-bloc-wrapper{
        background: white;
        border-radius: 50px;
        border: 0.5px solid var(--color-primary);
        padding: 4px 24px 4px 12px;
        margin: 0 auto;
        min-height: 70px;
        display: flex;
    }

    .product-img{
        width: 76px;
        height: 76px;
        background-position: 50%;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 50%;
    }

    .product-infos{
        display: flex;
        flex-direction: column;
    }

    .product-total-price{
        font-size: 1.4rem;
    }

    .product-price{
        font-size: 0.8rem;
    }

    .product-qty{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
    }
</style>